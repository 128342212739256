<template>
  <div :id="id" :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
import resize from './mixins/resize'

export default {
  name: 'MoveInfo',
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '3rem'
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chart: null,
      xData: [],
      chartData: {}
    }
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          // if (newValue) {
          this.chartData = newValue
          // } else {
          //   this.chartData = {
          //     xData: [],
          //     yData: []
          //   }
          // }
          this.initChart()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id))
      // echart配置
      var option = {
        tooltip: {
          axisPointer: {
            type: 'shadow',
            label: {
              show: true,
              color: '#fff',
              backgroundColor: '#409EFF'
            }
          },
          //提示框
          formatter: function(params) {
            return params.name + ':' + params.value[1] + '~' + params.value[2]
          } //数据的值
        },
        grid: {
          //绘图网格
          left: '3%',
          right: '3%',
          top: '1%',
          bottom: '0%',
          containLabel: true,
          color: '#000000'
        },
        xAxis: {
          axisTick: {
            formatter: function(value) {
              console.log(value, 'sss')
            },
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1.5,
              color: '#BBBBBB'
            }
          },
          axisLabel: {
            color: '#797979',
            rotate: 40,
            interval: 0,
            formatter: function(value) {
              // 格式化X轴刻度信息为需要显示的
              if (value.slice(-5) === '00:00') {
                return value.substr(0, 5)
              }
            }
          },
          splitLine: {
            show: false
          },
          data: this.chartData.dayList
          // axisLabel: {
          //   interval: 0
          // }
        },
        dataZoom: [
          {
            show: false,
            start: 0,
            end: 43200
          }
        ],
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              width: 1.5,
              color: '#BBBBBB'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#797979'
          },
          splitLine: {
            show: true
          },
          data: this.chartData.yData
        },
        series: [
          // 用空bar来显示四个图例
          {
            type: 'custom',
            renderItem: function(params, api) {
              //开发者自定义的图形元素渲染逻辑，是通过书写 renderItem 函数实现的
              var categoryIndex = api.value(0) //这里使用 api.value(0) 取出当前 dataItem 中第一个维度的数值。
              var start = api.coord([api.value(1), categoryIndex]) // 这里使用 api.coord(...) 将数值在当前坐标系中转换成为屏幕上的点的像素值。
              var end = api.coord([api.value(2), categoryIndex])
              var height = 20 //柱体宽度

              return {
                type: 'rect', // 表示这个图形元素是矩形。还可以是 'circle', 'sector', 'polygon' 等等。
                shape: echarts.graphic.clipRectByRect(
                  {
                    // 矩形的位置和大小。
                    x: start[0],
                    y: start[1] - height / 2,
                    width: end[0] - start[0],
                    height: height
                  },
                  {
                    // 当前坐标系的包围盒。
                    x: params.coordSys.x,
                    y: params.coordSys.y,
                    width: params.coordSys.width,
                    height: params.coordSys.height
                  }
                ),
                style: api.style()
              }
            },
            encode: {
              x: [1, 2], // data 中『维度1』和『维度2』对应到 X 轴
              y: 0 // data 中『维度0』对应到 Y 轴
            },
            data: this.chartData.series
          }
        ]
      }
      this.chart.setOption(option, true)
    }
  }
}
</script>

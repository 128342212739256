<template>
  <div :id="id" :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
import resize from './mixins/resize'

export default {
  name: 'MoveInfo',
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '3rem'
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chart: null,
      chartData: {}
    }
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          // if (newValue) {
          this.chartData = { ...newValue }
          // } else {
          //   this.chartData = {
          //     xData: [],
          //     yData: []
          //   }
          // }
          this.initChart()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id))
      let dataRight = this.chartData.dataRight
      let dataLeft = this.chartData.dataLeft
      const option = {
        backgroundColor: '#fff',
        grid: {
          top: '15%',
          bottom: '10%' //也可设置left和right设置距离来控制图表的大小
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter: function(params) {
            const res =
              '<div> ' +
              params[0].axisValue +
              '</div>' +
              '<div><span>关机时间：</span>' +
              dataRight[params[0].dataIndex] +
              '</div>' +
              '<div><span>开机时间：</span>' +
              dataLeft[params[0].dataIndex] +
              '</div>'
            return res
          }
        },
        legend: {
          // data: ['工作时长', '检查人次'],
          data: ['开机时间', '关机时间'],
          top: '0%'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            show: true
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1.5,
              color: '#BBBBBB'
            }
          },
          axisLabel: {
            rotate: 40,
            color: '#797979'
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          data: this.chartData.xData
        },
        yAxis: [
          {
            type: 'value',
            name: '',
            axisLine: {
              show: true,
              lineStyle: {
                width: 1.5,
                color: '#BBBBBB'
              }
            },
            axisTick: {
              show: false
            },
            min: 0,
            max: 23,
            axisLabel: {
              formatter: function(value) {
                var texts = []
                if (value == 0) {
                  texts.push('00:00')
                } else if (value <= 5) {
                  texts.push('05:00')
                } else if (value <= 10) {
                  texts.push('10:00')
                } else if (value <= 15) {
                  texts.push('15:00')
                } else if (value <= 20) {
                  texts.push('20:00')
                } else {
                  texts.push('23:00')
                }
                return texts
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed'
                }
              }
            }
          }
        ],
        series: [
          {
            name: '开机时间',
            type: 'line',
            barWidth: 20,
            itemStyle: {
              color: '#8D79FB'
            },
            data: this.chartData.yDataleft
          },
          {
            name: '关机时间',
            type: 'line',
            barWidth: 20,
            itemStyle: {
              color: '#CC3333'
            },
            data: this.chartData.yDataright
          }
        ]
      }
      this.chart.setOption(option, true)
    }
  }
}
</script>

<!--
 * @Author: xingwanjiang
 * @Date: 2021-10-13 16:26:52
 * @LastEditors: WangPei
 * @LastEditTime: 2024-06-28 14:13:22
 * @Description: 这是单页面
-->

<template>
  <div class="main">
    <div class="main-info">
      <div class="main-info-content">
        <div class="MIC-name">
          <div class="MIC-name-avatar">
            <!-- <el-avatar shape="square" /> -->
          </div>
          <div class="MIC-name-content">
            <span v-if="info.biHospitalInfoDTO">{{ info.biHospitalInfoDTO.hospitalName||'暂无数据'}}</span>
          </div>
        </div>
        <div style="display:flex;justify-content: space-between;">
          <div class="MIC-eqip" v-if="info.biEquipInfoDTO">{{ info.biEquipInfoDTO.hospitalEquipName || info.biEquipInfoDTO.equipName}}</div>
          <div class="MIC-state">
            <!-- <img class="MIC-state-img" src="../assets/equip/equip_state.png">
            <div class="MIC-state-name">设备状态:</div>-->
            <div class="success MIC-state-name" v-if="info.biEquipInfoDTO.onLine==='Y'">{{info.biEquipInfoDTO.onLineName}}</div>
            <span class="error MIC-state-name" v-else>离线</span>
          </div>
        </div>
        <div class="MIC-time">
          <div class="MIC-time-content">{{ day }}</div>
          <div class="MIC-time-content" style="text-align:center">{{ week }}</div>
          <div class="MIC-time-content" style="text-align:right">{{ time }}</div>
        </div>
      </div>
      <div class="main-info-business">
        <div class="MIB-date">
          <div class="MIB-date-label">配置正本日期：</div>
          <div class="MIB-date-content">{{ this.info.viceBookDate||'暂无数据' }}</div>
        </div>
        <div class="MIB-date">
          <div class="MIB-date-label">配置副本日期：</div>
          <div class="MIB-date-content">{{ this.info.justBookDate||'暂无数据' }}</div>
        </div>
        <div class="MIB-button">
          <div class="MIB-button-item" :class="change==='real'?'bg':''" @click="handleChange('real')">正本</div>
          <div class="MIB-button-item" :class="change==='reals'?'bg':''" @click="handleChange('reals')">副本</div>
        </div>
        <img class="MIB-img" :src="businessList" @click="handleOpenImg(businessList)" alt="暂无资源" />
      </div>
      <div class="main-info-equip">
        <!-- <div class="MIE-title">
          <img class="MIE-title-img" src="../assets/equip/equip_info.png">
          <div class="MIE-title-name">设备信息</div>
        </div>-->
        <div class="MIB-date">
          <div class="MIB-date-label">许可设备名称：</div>
          <div v-if="info.biEquipInfoDTO" class="MIB-date-content">{{ info.biEquipInfoDTO.equipModelName }}</div>
        </div>
        <div class="MIB-date">
          <div class="MIB-date-label">具体型号：</div>
          <div v-if="info.biEquipInfoDTO" class="MIB-date-content">{{ info.biEquipInfoDTO.equipName }}</div>
        </div>
        <div class="MIB-date">
          <div class="MIB-date-label">品牌：</div>
          <div v-if="info.biEquipInfoDTO" class="MIB-date-content">{{ info.biEquipInfoDTO.equipBrandName }}</div>
        </div>
        <div class="MIB-date">
          <div class="MIB-date-label">产品序列号：</div>
          <div v-if="info.biEquipInfoDTO" class="MIB-date-content">{{ info.biEquipInfoDTO.seriesNumber }}</div>
        </div>
        <div class="MIB-date">
          <div class="MIB-date-label">装机日期：</div>
          <div v-if=" info.biEquipInfoDTO" class="MIB-date-content">{{ info.biEquipInfoDTO.activeTime }}</div>
        </div>
      </div>
      <div class="main-info-button" @click="handleLogin" v-if="!isLogin">
        <div class="MIB-content">进入机房,点击登录</div>
        <!-- <div class="MIB-des">
          进入机房，请点击登录
        </div>-->
      </div>
      <div class="main-info-button" v-else>
        <div class="MIB-content success" @click="handleFillInfo">信息维护</div>
        <div class="MIB-des">
          您已登录,退出请点击
          <span class="out" @click="handleLoginout">退出登录</span>
        </div>
      </div>
    </div>
    <!-- 右侧部分 -->
    <div class="main-content">
      <div class="main-content-card">
        <div class="MCC-item bg-tem pad-right">
          <div class="MCC-item-title">温度</div>
          <div class="MCC-item-content" v-if="roomInfo">{{ roomInfo.temperature}}℃</div>
        </div>
        <div class="MCC-item bg-hum pad-right">
          <div class="MCC-item-title">湿度</div>
          <div class="MCC-item-content" v-if="roomInfo">{{ roomInfo.dampness }}%</div>
        </div>
        <div class="MCC-item bg-ele pad-right">
          <div class="MCC-item-title">电流</div>
          <div class="MCC-item-content" v-if="roomInfo">{{ roomInfo.electricCurrent }}A</div>
        </div>
        <div class="MCC-item bg-vol">
          <div class="MCC-item-title">电压</div>
          <div class="MCC-item-content" v-if="roomInfo">
            {{ roomInfo.electricVoltage }}V
          </div>
        </div>
      </div>
      <div class="main-content-info">
        <div class="MCI-time">
          <el-card>
            <div slot="header">
              <div class="MCI-header">
                <div class="MCI-title">开关机时间</div>
                <el-date-picker :clearable="false"  v-model="timeDate" style="margin-left: 10px;width: 310px;"
                      type="daterange" range-separator="-" :picker-options="pickerOptions" start-placeholder="开始日期"
                      end-placeholder="结束日期"  @change="getInfo()" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="开始日期"
                       />
                <!-- <el-date-picker
                  v-model="timeDate"
                  type="daterange"
                  size="mini"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  @change="getInfo()"
                /> -->
                <!-- <el-date-picker v-model="timeDate" type="date" size="mini" placeholder="选择日期" value-format="yyyy-MM-dd" clearable @change="getInfo()" /> -->
              </div>
            </div>
            <use-time id="usetime" :form="usetime" />
          </el-card>
          <el-card class="MCI-content">
            <div slot="header">
              <span class="MCI-title">设备接口安全管理</span>
            </div>
            <div class="MCI-content-des">
              <div v-if="tableData && tableData.length>0">
                <!-- <div class="mag-top" v-for="item in info.equipInterfaceMaintainInfoDTOS" :key="item.id">

                  <div class="MCD-content">
                    <div class="MCD-content-title">接口名称:</div>
                    <div class="MCD-content-des">{{ item.interfaceName }}</div>
                  </div>
                  <div class="MCD-content">
                    <div class="MCD-content-title">启用时间:</div>
                    <div class="MCD-content-des">{{ item.enableTime }}</div>
                  </div>
                </div>-->
                <template>
                  <el-table :data="tableData" style="width: 100%" :header-cell-style="{color:'#333'}">
                    <el-table-column prop="date" label="序号" width="55px" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.$index+1 }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="networkModusName" label="网络形式" />
                    <el-table-column prop="interfaceUse" label="接口用途" />
                    <el-table-column prop="dataInterfaceName" label="数据接口" />
                    <el-table-column prop="agreementContentName" label="协议名称" />
                    <el-table-column prop="accessAddress" label="访问地址" width="200" />
                    <el-table-column prop="safetyStatus" label="安全状态" />
                  </el-table>
                </template>
              </div>
              <el-empty v-else description="暂无数据" />
            </div>
          </el-card>
        </div>
        <div class="MCI-info">
          <el-card>
            <div slot="header">
              <div class="MCI-header">
                <div class="MCI-title">检查时间分布</div>
                    <el-date-picker :clearable="false" v-model="checkTime" style="margin-left: 10px;width: 310px;"
                      type="daterange" range-separator="-" :picker-options="pickerOptions" start-placeholder="开始日期"
                      end-placeholder="结束日期"  @change="fetchWorkTime()" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="开始日期"
                       />
                <!-- <el-date-picker
                  v-model="checkTime"
                  type="date"
                  size="mini"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  clearable
                 
                /> -->
              </div>
            </div>
            <!-- <open-check id="moveinfo" /> -->
            <OpenTime id="OpenTime" :form="OpenTime" />
            <!-- <move-info id="moveinfo" :form="MoveInfo" /> -->
          </el-card>
          <!-- 进出信息登记 -->
          <el-card class="MCI-content">
            <div slot="header">
              <span class="MCI-title">进出信息登记</span>
            </div>
            <div class="MCI-content-des">
              <div class="mag-top" v-for="item in equipList" :key="item.id" @click="open(item.bid)" title="查看详情">
                <!-- <el-row>
                  <el-col :span="12">
                    <div class="MCD-content">
                      <div class="MCD-content-title">工作事项:</div>
                      <div class="MCD-content-des">{{ item.peerPersonnel}}</div>
                      <div class="MCD-content-des">{{ item.peerPersonnel}}</div>
                      <div class="MCD-content-des">{{ item.peerPersonnel}}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="MCD-content">
                      <div class="MCD-content-title">工作事项:</div>
                      <div class="MCD-content-des">{{ item.workMatterName}}</div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="MCD-content">
                      <div class="MCD-content-title">需要维修:</div>
                      <div class="MCD-content-des">
                        <el-tag type="danger" v-if="item.isRepair==='YES'" size="mini" effect="dark">
                          是
                        </el-tag>
                        <el-tag type="success" v-if="item.isRepair==='NO'" size="mini" effect="dark">
                          否
                        </el-tag>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="MCD-content">
                      <div class="MCD-content-title">日期:</div>
                      <div class="MCD-content-des">{{ item.gmtUpdate }}</div>
                    </div>
                  </el-col>
                </el-row>-->
                <div class="MCD-content">
                  <div class="MCD-content-title">工作事项:</div>
                  <div class="MCD-content-des">
                    <div>{{ item.workMatterName}}</div>
                    <div>{{ item.gmtUpdate}}</div>
                  </div>
                </div>
                <div class="MCD-content">
                  <div class="MCD-content-title">工作描述:</div>
                  <div class="MCD-content-des">{{ item.workDesc}}</div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <login-dialog ref="dialog" @ok="handleCloseDialog" />
      <fill-info ref="fillinfo" @ok="init" />
      <view-details ref="details" />
      <open-img ref="openimg" />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
// import MoveInfo from './components/echarts/move-info'
import UseTime from './components/echarts/use-time';
// import OpenCheck from './components/echarts/open-check'
import LoginDialog from './components/login-dialog';
import FillInfo from './components/fiil-info';
import OpenImg from './components/open-img';
import OpenTime from './components/echarts/open-time';
import ViewDetails from './components/view-details';
import {
  countEquipUseTime,
  fetchEquipInfo,
  fetchEquipEnvInfo,
  getEquipList,
  countEquipPersonNum,
  countWorkTimes,
  countCloseTimes,
  equipSelectAll,
  recordOfAccess
} from '../api/index.js';

export default {
  components: {
    // MoveInfo,
    UseTime,
    LoginDialog,
    FillInfo,
    OpenImg,
    OpenTime,
    ViewDetails
    // OpenCheck
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now(); // 8.64e7 毫秒数代表一天
        },
      }, 
      // 人员登录状态
      isLogin: false,
      info: {},
      // 环境信息
      roomInfo: {},
      equipList: [],
      MoveInfo: {},
      usetime: {},
      day: '',
      time: '',
      week: '',
      businessList: '',
      change: 'reals',
      title: '副本',
      OpenTime: {},
      // 开关机时间
      timeDate: [moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'), moment().subtract(0, 'days').endOf('day').format('YYYY-MM-DD')],
      // 检查时间分布
      checkTime:  [moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'), moment().subtract(0, 'days').endOf('day').format('YYYY-MM-DD')],
      tableData: [],
      resData: {}
    };
  },
  created() {
    this.init();
    this.fetchTime();
  },
  methods: {
    open(bid) {
      const data = {
        bid: bid
      };

      recordOfAccess(data).then(res => {
        const result = res.data;
        // this.resData = res.data;
        // console.log(result,'hahah');

        this.$refs.details.init22(result);
        // console.log(this.resData,'父组件');
      });
    },
    init() {
      this.fetchWorkTime();
      this.getInfo();
      this.getEnvInfo();
      this.fetchEquipList();
      this.fetchEquipPersonNum();
      this.fetchEquip();
      // this.recordOfAccess()
      // this.getCountEquipUseTime()
      // this.fetchList()
      // this.handleChange('deal')
    },
    fetchEquip() {
      const cid = this.$route.query.bid;
      equipSelectAll(cid).then(res => {
        if (res.code === 200) {
          const result = res.data;
          this.tableData = result;
        }
      });
    },
    /**
     * @description: 获取
     * @return {*}
     */

    fetchWorkTime() {
      const data = {
        equipInfoBid: this.$route.query.bid,
        checkTimeParam: this.checkTime
      };
      countWorkTimes(data).then(res => {
        const data = res.data;
        const yData = [];
        const series = [];
        let startData = 1653951600;
        const length = 43200;
        const dayList = [];
        // const result = []
        // dayList.push(moment(startData).format('HH:mm:ss'))
        for (let i = startData; i < startData + length; i++) {
          dayList.push(moment(parseInt(i * 1000)).format('HH:mm:ss'));
        }
        if (data && data.length > 0) {
          data.map((item, index) => {
            yData.push(item.answnerTime);
            const list = item.checkSequenceDetailDTOList;
            if (list && list.length > 0) {
              list.map(v => {
                const result = {
                  itemStyle: {
                    normal: {
                      color: '#00CC00'
                    }
                  },
                  name: '设备检查时间',
                  value: [index, v.checkStartTime, v.checkEndTime]
                };
                series.push(result);
              });
            }
          });
        }
        const echart = {
          yData: yData,
          series: series,
          dayList: dayList
        };
        this.OpenTime = echart;
      });
    },

    handleOpenImg(data) {
      const title = this.title;
      this.$refs.openimg.init(data, title);
    },
    handleChange(data) {
      if (data === 'reals') {
        this.title = '副本';
        if (this.info.viceBookList && this.info.viceBookList.length > 0) {
          this.businessList = this.info.viceBookList[0].url;
        } else {
          this.businessList = null;
        }
      } else {
        this.title = '正本';
        if (this.info.justBookList && this.info.justBookList.length > 0) {
          this.businessList = this.info.justBookList[0].url;
        } else {
          this.businessList = null;
        }
      }
      this.change = data;
    },
    /**
     * @description: 获取当前时间
     * @param {*}
     * @return {*}
     */

    fetchTime() {
      setInterval(() => {
        this.day = moment().format('YYYY.MM.DD');
        this.time = moment().format('HH:mm:ss');
        this.week = this.getWeek();
      }, 1000);
    },
    getWeek() {
      // 参数时间戳
      const week = moment().day();
      switch (week) {
        case 1:
          return '星期一';
        case 2:
          return '星期二';
        case 3:
          return '星期三';
        case 4:
          return '星期四';
        case 5:
          return '星期五';
        case 6:
          return '星期六';
        case 0:
          return '星期日';
      }
    },
    /**
     * @description: 机房-使用时长（最近一周）
     * @param {*}
     * @return {*}
     */

    getCountEquipUseTime() {
      const data = {
        equipInfoBid: this.$route.query.bid
      };
      countEquipUseTime(data).then(res => {
        if (res.code === 200) {
          const result = res.data;
          const days = [];
          const data = [];
          const hours = [];
          if (result && result.length > 0) {
            result.map((item, index) => {
              days.push(item.countTime);
              hours.push(item.currentSumTime);
              if (item.hourCountTime && item.hourCountTime.length > 0) {
                const dataList = item.hourCountTime;
                dataList.map(ite => {
                  const list = [];
                  list.push(index);
                  list.push(ite);
                  list.push(7);
                  data.push(list);
                });
              }
            });
          }
          const echart = {
            days: days,
            data: data,
            hours: hours
          };
          this.usetime = echart;
        }
      });
    },
    /**
     * @description:获取检查人次的数据
     * @param {*}
     * @return {*}
     */

    fetchEquipPersonNum() {
      const data = {
        equipInfoBid: this.$route.query.bid
      };
      countEquipPersonNum(data).then(res => {
        if (res.code === 200) {
          const result = res.data;
          const xData = [];
          const yData = [];
          if (result && result.length > 0) {
            result.map(item => {
              xData.push(item.answerTime);
              yData.push(item.checkPersonNum);
            });
          }
          const echart = {
            xData: xData,
            yData: yData
          };
          this.MoveInfo = echart;
        }
      });
    },
    /**
     * @description: 获取设备信息列表
     * @param {*}
     * @return {*}
     */

    fetchEquipList() {
      const data = {
        equipBid: this.$route.query.bid
        // bid:this.$route.query.bid
      };
      getEquipList(data).then(res => {
        if (res.code === 200) {
          const result = res.data;
          this.equipList = result;
        }
      });
    },
    /**
     * @description:设备信息
     * @param {*}
     * @return {*}
     */

    getEnvInfo() {
      const data = {
        equipInfoBid: this.$route.query.bid
      };
      fetchEquipEnvInfo(data).then(res => {
        if (res.code === 200) {
          const result = res.data;
          this.roomInfo = result;
        }
      });
    },
    /**
     * @description: 获取设备信息
     * @param {*}
     * @return {*}
     */

    getInfo() {
      let startTime = null;
      let endTime = null;
      if (this.timeDate && this.timeDate.length > 0) {
        startTime = this.timeDate[0];
        endTime = this.timeDate[1];
        const time = 30 * 24 * 3600;
        const curDate = moment(startTime).format('X');
        const openTime = moment(endTime).format('X');
        const open = openTime - curDate;
        if (open > time) {
          this.$message.error('时间区间最长选择30天!');
          return false;
        }
      }
      const data = {
        equipInfoBid: this.$route.query.bid
      };
      fetchEquipInfo(data).then(res => {
        if (res.code === 200) {
          const result = res.data;
          this.info = result;
          const data = {
            // hospitalBid: this.info.biEquipInfoDTO.cid,
            equipInfoBid: this.$route.query.bid,
            // checkTimeParam: this.timeDate
            startTime: startTime,
            endTime: endTime
            // queryTime: '2022-06'
          };
          countCloseTimes(data).then(res => {
            const result = res.data.switchTimeDetailDTOList;
            const xData = [];
            const yDataleft = [];
            const yDataright = [];
            const dataLeft = [];
            const dataRight = [];
            if (result && result.length > 0) {
              result.map(item => {
                xData.push(item.answersTime);
                dataLeft.push(item.bootTimeHour);
                dataRight.push(item.shutdownTimeHour);
                yDataleft.push(this.fomatTime(item.bootTimeHour));
                yDataright.push(this.fomatTime(item.shutdownTimeHour));
              });
            }
            const echart = {
              xData: xData,
              yDataleft: yDataleft,
              yDataright: yDataright,
              dataLeft: dataLeft,
              dataRight: dataRight
            };
            this.usetime = echart;
          });
          if (this.info.viceBookList && this.info.viceBookList.length > 0) {
            this.businessList = this.info.viceBookList[0].url;
          } else {
            this.businessList = null;
          }
        }
      });
    },
    fomatTime(data) {
      if (data) {
        let number = null;
        if (data.slice(0, 1) === '0') {
          number = Number(data.slice(1, 2));
        } else {
          number = Number(data.slice(0, 2));
        }
        const point = Number((data.slice(3, 5) / 60).toFixed(2));
        const result = number + point;
        return result;
      }
    },
    /**
     * @description: 登录
     * @param {*}
     * @return {*}
     */

    handleLogin() {
      this.$refs.dialog.init();
    },
    handleFillInfo() {
      const data = this.info.biEquipInfoDTO;
      this.$refs.fillinfo.init(data);
    },
    /**
     * @description: 登录页面的回调
     * @param {*}
     * @return {*}
     */

    handleCloseDialog() {
      const token = this.$store.state.userInfo.token;
      if (token) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    },
    /**
     * @description: 退出登录
     * @param {*}
     * @return {*}
     */

    handleLoginout() {
      this.$confirm(`您确定要退出登录吗?`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(() => {
          this.$store.commit('SET_USERINFO', {});
          this.isLogin = false;
          // this.handleCloseDialog()
          // this.$router.push({
          //   path: '/detail'
          // })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  .el-card ::v-deep .el-card__header {
    padding: 0.18rem 0.2rem;
  }
  .el-card ::v-deep .el-card__body {
    padding: 0.2rem;
  }
  &-info {
    position: relative;
    width: 4.6rem;
    height: 100%;
    background-color: #545b64;
    color: #fff;
    &-business {
      height: 4rem;
      padding: 0.3rem;
      background-color: #666b72;
      .MIB-date {
        display: flex;
        margin-bottom: 0.05rem;
        &-label {
          flex: 0.6;
          padding: 0.1rem;
          text-align: right;
          margin-right: 0.05rem;
          font-size: 0.18rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #e3e3e3;
          background-color: #54595e;
        }
        &-content {
          flex: 1;
          padding: 0.1rem;
          /* margin-right: 0.1rem; */
          font-size: 0.18rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #e3e3e3;
          background-color: #54595e;
        }
      }
      .MIB-button {
        display: flex;
        &-item {
          flex: 1;
          padding: 0.2rem 0;
          align-items: center;
          text-align: center;
          background: #000000;
          opacity: 0.8;
          font-size: 0.18rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #ffffff;
        }
        .bg {
          background: #0199e1;
        }
      }
      .MIB-img {
        width: 100%;
        height: 2.2rem;
        cursor: pointer;
      }
    }
    &-equip {
      margin-top: 0.1rem;
      padding: 0.3rem;
      background-color: #666b72;
      .MIE-title {
        padding: 0.3rem;
        font-size: 0.26rem;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        font-weight: 500;
        color: #a0d1f9;
        border-bottom: 2px solid #767a81;
        display: flex;
        &-img {
          width: 0.32rem;
          height: 0.32rem;
        }
        &-name {
          padding: 0.05rem 0 0 0.1rem;
        }
      }
      .MIB-date {
        display: flex;
        margin-bottom: 0.05rem;
        &-label {
          flex: 0.6;
          padding: 0.1rem;
          text-align: right;
          margin-right: 0.05rem;
          font-size: 0.18rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #e3e3e3;
          background-color: #54595e;
        }
        &-content {
          flex: 1;
          padding: 0.1rem;
          /* margin-right: 0.1rem; */
          font-size: 0.18rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #e3e3e3;
          background-color: #54595e;
        }
      }
    }
    &-content {
      /* height: 1.4rem; */
      margin: 0.2rem;
      background: #545b64;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .MIC-name {
        margin-bottom: 0.2rem;
        font-size: 0.24rem;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        &-content {
          width: 100%;
          display: flex;
          align-items: center;
          /* margin-left: 0.2rem; */
          padding-left: 0.2rem;
          padding: 0.2rem;
          background: #666b72;
        }
      }
      .MIC-eqip {
        flex: 1;
        height: 0.5rem;
        line-height: 0.5rem;
        word-break: keep-all;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: 0.3rem;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        font-weight: 500;
        color: #a0d1f9;
      }
      .MIC-state {
        width: 0.75rem;
        height: 0.5rem;
        line-height: 0.5rem;
        /* padding-top: 0.3rem; */
        font-size: 0.24rem;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        font-weight: 500;
        color: #e3e3e3;
        display: flex;
        &-img {
          width: 0.32rem;
          height: 0.32rem;
        }
        &-name {
          padding: 0.05rem 0 0 0.1rem;
        }
        .success {
          padding-left: 0.1rem;
          color: #67c23a;
        }
        .error {
          padding-left: 0.1rem;
          color: #f56c6c;
        }
      }
      .MIC-time {
        padding-top: 0.2rem;
        display: flex;
        &-content {
          flex: 1;
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    &-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0.2rem;
      .MIB-content {
        text-align: center;
        padding: 0.2rem;
        font-size: 0.22rem;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        font-weight: 500;
        color: #ffffff;
        background: #0199e1;
        border-radius: 4px;
        cursor: pointer;
      }
      .success {
        background: #68bf7b;
      }
      .out {
        color: red;
        cursor: pointer;
      }
      .MIB-des {
        padding-top: 0.2rem;
        font-size: 0.24rem;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        font-weight: 500;
        color: #e3e3e3;
      }
    }
  }
  &-content {
    flex: 1;
    /* margin: 0.2rem; */
    padding: 0.1rem;
    width: calc(100% - 4.6rem);
    height: 100%;
    overflow: hidden;
    &-card {
      width: 100%;
      height: 1.6rem;
      display: flex;
      .MCC-item {
        flex: 1;
        padding: 0.3rem;
        &-title {
          font-size: 0.28rem;
          font-family: SourceHanSansCN, SourceHanSansCN-Medium;
          font-weight: 500;
          color: #ffffff;
        }
        &-content {
          padding-top: 0.5rem;
          font-size: 0.45rem;
          font-family: Roboto, Roboto-Bold;
          font-weight: 700;
          text-align: left;
          color: #ffffff;
        }
      }
      .pad-right {
        margin-right: 0.1rem;
      }
      .bg-tem {
        background-image: url('../assets/equip/equip_tempera.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .bg-hum {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../assets/equip/equip_hum.png');
      }
      .bg-ele {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../assets/equip/equip_ele.png');
      }
      .bg-vol {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../assets/equip/equip_vol.png');
      }
    }
    &-info {
      margin-top: 0.1rem;
      display: flex;
      .MCI-time {
        flex: 1;
        margin-right: 0.1rem;
      }
      .MCI-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .MCI-title {
        font-size: 0.24rem;
        font-family: SourceHanSansCN, SourceHanSansCN-Medium;
        font-weight: 500;
        color: #05091b;
      }
      .MCI-content {
        margin-top: 0.1rem;
        height: auto;
        &-des {
          height: 35vh;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 4px;
          }
          &:hover::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, 0.4);
          }
          &:hover::-webkit-scrollbar-track {
            background: hsla(0, 0%, 53%, 0.1);
          }
          .mag-top {
            padding: 0.15rem;
            margin-bottom: 0.1rem;
            background: #f5f6f8;
            border: 1px solid #eff0f4;
            border-radius: 4px;
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.07);
            margin-bottom: 0.1rem;
          }
          .mag-top:hover {
            cursor: pointer;
          }

          .MCD-content {
            display: flex;
            margin: 0.1rem;
            border-radius: 4px;
            box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.07);
            /* padding-bottom: 0.1rem; */
            font-size: 0.2rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 500;
            color: #3e3f45;
            &-title {
              background: #fff;
              padding: 0.2rem 0.1rem 0.1rem 0.1rem;
              color: #318cd6;
            }
            &-des {
              flex: 1;
              display: flex;
              justify-content: space-between;
              background: #fff;
              padding: 0.1rem;
              margin-left: 0.1rem;
              line-height: 0.5rem;
            }
          }
        }
      }
      .MCI-info {
        flex: 1;
      }
    }
  }
}
</style>


<!--
 * @Author: xingwanjiang
 * @Date: 2022-03-09 08:59:18
 * @LastEditors: xingwanjiang
 * @LastEditTime: 2022-05-17 15:44:18
 * @Description: 检查信息维护
-->

<template>
  <el-dialog :title="title||'--'" :visible.sync="dialogVisible" width="50%">
    <div class="dialog-content">
      <img class="dialog-content-img" :src="img" alt="格式不支持">
    </div>
    <span slot="footer">
      <el-button size="mini" type="primary" @click="dialogVisible=false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      img: '',
      dialogVisible: false,
      title: ''
    }
  },
  methods: {
    /**
     * @description: 弹窗初始化
     * @param {*}
     * @return {*}
     */

    init(data, title) {
      this.title = title
      this.img = data
      //   this.$refs.form.resetFields()
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  .el-dialog__body {
    padding: 0 !important;
  }
  &-content {
    padding: 0 0.5rem;
    &-img {
      width: 100%;
      height: 50vh;
    }
  }
}
</style>

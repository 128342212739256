<!--
 * @Author: xingwanjiang
 * @Date: 2022-03-09 08:59:18
 * @LastEditors: xingwanjiang
 * @LastEditTime: 2022-03-15 08:49:05
 * @Description: 人员登录
-->

<template>
  <el-dialog title="人员登录" :visible.sync="dialogVisible" width="30%" :before-close="handleCancle">
    <div class="dialog-content">
      <el-form :model="form" size="small" :rules="rules" ref="form" class="login-content-form">
        <el-form-item prop="loginName">
          <el-input v-model="form.loginName" placeholder="请输入用户名">
            <i slot="prefix" class="el-input__icon el-icon-s-custom"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="loginPassword">
          <el-input v-model="form.loginPassword" placeholder="请输入密码" show-password>
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button size="mini" @click="handleCancle">取 消</el-button>
      <el-button size="mini" type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { userLogin } from '../../api/index.js'
import JsEncrypt from 'jsencrypt'
export default {
  data() {
    return {
      form: {},
      dialogVisible: false,
      rules: {
        loginName: [{ required: true, message: '请输入登录账号', trigger: 'change' }],
        loginPassword: [{ required: true, message: '请输入密码', trigger: 'change' }]
      }
    }
  },
  methods: {
    /**
     * @description: 取消
     * @param {*}
     * @return {*}
     */

    handleCancle() {
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    /**
     * @description: 弹窗初始化
     * @param {*}
     * @return {*}
     */

    init() {
      // this.$refs.form.resetFields()
      this.form = {}
      this.dialogVisible = true
    },
    /**
     * @description:表单提交
     * @param {*}
     * @return {*}
     */

    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { loginName, loginPassword } = this.form
          const publicKey =
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCkoLj+5fgnVjNHfUAQMfWB0PellKi7gbBZIkheqlfyDwHLZKsubxINWJhSGJpkWCCAfsG5MhP0K1Gv+ma3vJAEvi+O32Rxdd8GfOCZ7cJ2kCQX5e4r5uAPuYCh0YFsa7rGKl6gpnTx8I3H6K48x+E38z2Mu5VRych0klmqNjsDDQIDAQAB'
          const encrypt = new JsEncrypt()
          encrypt.setPublicKey(publicKey)
          const data = {
            loginName: loginName,
            loginPassword: encrypt.encrypt(loginPassword),
            isInvalid: 1
          }
          userLogin(data).then(res => {
            if (res.code == 200) {
              const data = res.data
              this.$store.commit('SET_USERINFO', data)
              // const data = true
              this.$emit('ok')
              this.dialogVisible = false
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  .el-dialog__body {
    padding: 0 !important;
  }
  &-content {
    padding: 0 0.5rem;
  }
}
</style>

<template>
  <el-dialog
    title="详情"
    :visible.sync="dialogVisible"
    width="40%"
    :before-close="handleCancle"
  >
    <div class="dialog-content">
      <el-form
        :model="form"
        size="small"
        :rules="rules"
        ref="form"
        class="login-content-form"
      >
        <div class="content">
          <div class="child">
            <span>医院设备名称 : {{ resData.equipName }}</span>
            <span>科室 : {{ resData.respectiveDepartment }}</span>
            <span>保管人陪同 : {{ resData.custodianAccompany }}</span>
            <span>工作事项 : {{ resData.workDesc }}</span>
            <span>联系方式 : {{ resData.contactDetails }}</span>
            <span
              >出入时间 : {{ resData.startTime }}-{{ resData.endTime }}</span
            >
            <span>同行人员 : {{ resData.peerPersonnel }}</span>
            <span>携带物品 : {{ resData.carriedItems }}</span>
          </div>
          <div class="child">
            <span>所属机房 : {{ resData.machineRoom }}</span>
            <span>保管人 : {{ resData.depositary }}</span>
            <span>移动设备 : {{ resData.deviceMovement }}</span>
            <span>维护人员 : {{ resData.maintenancePersonnel }}</span>
            <span>表单录入 : {{ resData.uploadPic }}</span>
          </div>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
// import {recordOfAccess} from '../../api/index.js'
export default {
  
  data() {
    return {
      dialogVisible: false,
      resData: {},
    };
  },

  methods: {
    init22(resData) {
    //  console.log(resData,'子组件');
     this.resData = resData;
     this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  // width: 600px;
  margin: auto;
  .child {
    flex: 1;
    display: flex;
    flex-direction: column;
    span {
      margin: 10px 0 10px 0;
      padding-left: 50px;
      // text-align: center;
    }
  }
}
</style>

<!--
 * @Author: xingwanjiang
 * @Date: 2022-03-09 08:59:18
 * @LastEditors: xingwanjiang
 * @LastEditTime: 2022-09-02 18:12:08
 * @Description: 检查信息维护
-->

<template>
  <el-dialog title="信息维护" :visible.sync="dialogVisible" width="800px" :before-close="handleCancle">
    <div class="dialog-content">
      <el-form ref="form" :validate-on-rule-change="false" size="mini" :model="form" :rules="rules" label-width="160px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="医院设备名称" prop="equipBid">
              <el-input v-model.trim="form.equipName" disabled clearable style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属机房" prop="machineRoom">
              <el-input v-model.trim="form.machineRoom" disabled clearable style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="科室" prop="respectiveDepartment">
              <el-select v-model="form.respectiveDepartment" disabled placeholder="请选择">
                <el-option v-for="item in departmentList" :key="item.id" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保管人" prop="depositary">
              <el-input v-model.trim="form.depositary" disabled clearable style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="保管人陪同" prop="custodianAccompany">
              <el-select v-model="form.custodianAccompany" placeholder="请选择">
                <el-option v-for="item in mobileList" :key="item.id" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="移动设备" prop="deviceMovement">
              <el-select v-model="form.deviceMovement" placeholder="请选择">
                <el-option v-for="item in mobileList" :key="item.id" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工作事项" prop="workMatter">
              <el-select v-model="form.workMatter" clearable placeholder="请选择">
                <el-option v-for="item in workList" :key="item.id" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维护人员" prop="maintenancePersonnel">
              <el-input v-model.trim="form.maintenancePersonnel" clearable style="width:100%" placeholder="请输入维护人员名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="contactDetails">
              <el-input v-model.trim="form.contactDetails" clearable style="width:100%" placeholder="请输入维护人员联系方式" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="表单录入" prop="isFillform">
              <el-button size="mini" type="primary" @click="isFillform('填写表单',{})">填写表单</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="出入时间" prop="time">
            <el-date-picker v-model="form.time" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" startTime-placeholder="请选择开始时间" endTime-placeholder="请选择结束时间" align="right" style="width:100%"></el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="同行人员" prop="peerPersonnel">
              <el-input v-model.trim="form.peerPersonnel" clearable style="width:100%" placeholder="请输入同行人员" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="携带物品" prop="carriedItems">
              <el-input v-model.trim="form.carriedItems" clearable style="width:100%" placeholder="请输入携带物品" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="工作描述" prop="workDesc">
              <el-input v-model.trim="form.workDesc" clearable style="width:100%" type="textarea" :rows="2" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="附件" prop="planSumNumber">
              <!-- <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="handleChange"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="handleCancle">取 消</el-button>
      <el-button size="mini" type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { addRoomManageList, getFindEquip } from '../../api/index.js'

export default {
  data() {
    return {
      // 移动设备
      mobileList: [
        {
          value: 0,
          label: '是'
        },
        {
          value: 1,
          label: '否'
        }
      ],
      // 科室字典表
      departmentList: [
        {
          value: 'OPERATION_ROOM',
          label: '手术室'
        },
        {
          value: 'MEDICINE_NUCLEAR',
          label: '核医学科'
        },
        {
          value: 'IMAGE_SECTION',
          label: '影像科'
        },
        {
          value: 'RADIATION_THERAPY',
          label: '放疗中心'
        },
        {
          value: 'PHYSICAL_EXAMINATION',
          label: '体验中心'
        },
        {
          value: 'RADIOLOGY_DEPARTMENT',
          label: '放射科'
        }
      ],
      // 工作事项
      workList: [
        {
          value: 'INSPECTION',
          label: '巡检'
        },
        {
          value: 'REPAIR',
          label: '维修'
        },
        {
          value: 'MAINTENANCE',
          label: '维保'
        },
        {
          value: 'OTHERS',
          label: '其他'
        }
      ],
      form: {},
      time: [],
      hospitalEquipList: [],
      // hospitalChange: [],
      dialogVisible: false,
      rules: {
        equipBid: [{ required: true, message: '请选择设备', trigger: 'blur' }],
        machineRoom: [{ required: false, message: '请输入名称', trigger: 'blur' }],
        respectiveDepartment: [{ required: false, message: '请输入监护人名称', trigger: 'blur' }],
        depositary: [{ required: false, message: '请输入监护人名称', trigger: 'blur' }],
        custodianAccompany: [{ required: true, message: '请选择', trigger: 'blur' }],
        deviceMovement: [{ required: false, message: '请选择', trigger: 'blur' }],
        // workMatter: [{ required: false, message: '请选择', trigger: 'blur' }],
        maintenancePersonnel: [{ required: true, message: '请输入维护人员名称', trigger: 'blur' }],
        contactDetails: [{ required: true, message: '请输入维护人员联系方式', trigger: 'blur' }],
        time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        startTime: [{ message: '请选择开始时间', trigger: 'blur' }],
        endTime: [{ message: '请选择结束时间', trigger: 'blur' }],
        peerPersonnel: [{ required: true, message: '请输入同行人员', trigger: 'blur' }],
        carriedItems: [{ required: true, message: '请输入携带物品', trigger: 'blur' }],
        workDesc: [{ required: true, message: '请输入工作描述', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // handleChange() {
    //   const data = this.form.isRepair;
    //   if (!data || data === 'NO') {
    //     this.time = [];
    //     this.handleSelectTime();
    //   }
    // },
    // handleSelectTime() {
    //   const time = this.time;
    //   if (time && time.length > 0) {
    //     this.form.startTime = time[0];
    //     this.form.endTime = time[1];
    //   } else {
    //     this.form.startTime = '';
    //     this.form.endTime = '';
    //   }
    // },
    //上传附件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    /**
     * @description: 弹窗初始化
     * @param {*}
     * @return {*}
     */
    hospitalChange(val) {
      let obj = {}
      obj = this.hospitalEquipList.find(item => {
        return item.bid === val
      })
      this.form.equipBid = obj.bid
      this.form.machineRoom = obj.engineRoom
      this.form.respectiveDepartment = obj.technicalOffices
      this.form.depositary = obj.depositary
      this.form.equipName = obj.hospitalEquipName
    },

    init() {
      // const bid = data.bid
      // this.form = {
      //   isRepair: 'NO'
      // };
      //   this.$refs.form.resetFields()
      const id = this.$route.query.bid
      this.dialogVisible = true
      getFindEquip(id).then(res => {
        if (res.code === 200) {
          const obj = { ...res.data }
          this.form.equipBid = obj.equipBid
          this.form.machineRoom = obj.engineRoom
          this.form.respectiveDepartment = obj.technicalOffices
          this.form.depositary = obj.depositary
          this.form.equipName = obj.hospitalEquipName
          this.form = { ...this.form }
          // this.hospitalEquipList = res.data
          // this.hospitalChange(bid)
          // this.hospitalChange = res.data;
        }
      })
    },
    /**
     * @description: 表单录入
     * @param {*}
     * @return {*}
     */

    isFillform() {
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    /**
     * @description: 取消
     * @param {*}
     * @return {*}
     */

    handleCancle() {
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    /**
     * @description:表单提交
     * @param {*}
     * @return {*}
     */

    handleSubmit() {
      this.$refs.form.validate(valid => {
        // if (valid) {
        //   const data = this.form;
        //   data.equipBid = this.$route.query.bid;
        //   data.userBid = this.$store.state.userInfo.user.userBid;
        //   if (data.time && data.time.length > 0) {
        //     data.startTime = data['time'][0];
        //     data.endTime = data['time'][1];
        //   }

        //   if (data.bid) {
        //     this.dialogVisible = false;
        //     this.$emit('ok');
        //     addEquipInfo(data).then(res => {
        //       if (res.code == 200) {
        //         this.$emit('ok');
        //         this.dialogVisible = false;
        //       }
        //     });
        if (valid) {
          const data = this.form
          if (data.time && data.time.length > 0) {
            data.startTime = data['time'][0]
            data.endTime = data['time'][1]
          }
          // if (data.bid) {
          // this.dialogVisible = false;
          // this.$emit('ok');
          addRoomManageList(data).then(res => {
            if (res.code === 200) {
              this.$emit('ok')
              this.dialogVisible = false
            }
          })
          // } else {
          //   return false;
          // }
        }
      })
      //   const data = true
      //   this.$emit('ok', data)
      // this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-content {
  padding: 0 50px 0 30px;
  max-height: 63vh;
  overflow: auto;
}

.dialog-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}

.dialog-content::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.dialog-content:hover::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 53%, 0.4);
}

.dialog-content:hover::-webkit-scrollbar-track {
  background: hsla(0, 0%, 53%, 0.1);
}

.centerYear > ::v-deep .el-form-item__label {
  width: 90px !important;
}
</style>

